import "intersection-observer"

import React, { useEffect } from "react"

type PropsGoogle = {
  id: string
  url: string | null
  size: string | null
}

export const AdElement: React.FC<PropsGoogle> = (props) => {
  const { id } = props

  //display ad
  useEffect(() => {
    const elm = document.getElementById(`div-gpt-ad-${id}`)
    let script: HTMLScriptElement | undefined
    if (elm) {
      script = document.createElement("script")
      script.innerHTML = `
        googletag.cmd.push(function () {
          googletag.display('div-gpt-ad-${id}');
        });
      `
      elm.appendChild(script)
    }
  }, [])

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
            <div id="div-gpt-ad-${id}" style=""></div>
          `
      }}
    />
  )
}
